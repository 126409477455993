<template>
  <div class="banner-box">
    <img src="@/assets/img/become-mentor.svg" alt="Ментор" class="bg-image">
    <img src="@/assets/img/become-mentor-mobile.svg" alt="Ментор" class="bg-image mobile">

    <div class="strips">
      <div class="strip" :class="`strip-${i}`" v-for="i in [1, 2]" :key="i">
        <span v-for="j in [0, 1, 2, 3]" :key="j">Стать ментором<img src="@/assets/img/star-separator.svg" alt="Star" class="separator"></span>
      </div>
    </div>

    <div class="content">
      <h2 class="title">Хочешь делиться опытом <br>и помогать другим людям?</h2>
      <div class="desc">Заполни анкету и присоединяйся к нашей команде менторов</div>
      <BaseButton big class="btn" :to="becomeMentorRoute">Стать ментором</BaseButton>
    </div>
  </div>
</template>

<script setup>
const becomeMentorRoute = useBecomeMentorRoute()
</script>

<style scoped lang="scss">
.banner-box {
  border-radius: 53px;
  overflow: hidden;
  height: 409px;
  background: var(--color-bg-secondary);
  position: relative;
  padding: 56px 68px;
  @media (max-width: 920px) {
    padding: 21px 16px;
    height: auto;
    border-radius: 32px;
  }
  & .bg-image {
    position: absolute;
    right: -30px;
    top: 0;
    &.mobile {
      display: none;
      @media (max-width: 920px) {
        display: unset;
        right: unset;
        top: unset;
        bottom: 42px;
        left: 50%;
        transform: translateX(-50%);
        max-width: none;
        width: 248px;
        object-fit: contain;
      }
    }
    @media (max-width: 920px) {
      display: none;
    }
  }
  & .content {
    position: relative;
    z-index: 2;
  }
  & .title {
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 20px;
    @media (max-width: 920px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 18px;
      & br {
        display: none;
      }
    }
  }
  & .desc {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  & .btn {
    margin-top: 24px;
    @media (max-width: 920px) {
      margin-top: 165px;
      width: 100%;
      display: flex;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      padding: 24px 26px;
    }
  }
}

.strip {
  background: var(--color-primary);
  padding: 15px 18px;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  display: flex;
  align-items: center;
  white-space: nowrap;
  left: 50%;
  bottom: 36px;
  user-select: none;
  @media (max-width: 920px) {
    padding: 8px 15px;
    bottom: 129px;
    left: 50%;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 16px;
  }
  & > span {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  & .separator {
    margin: 0 39px;
    @media (max-width: 920px) {
      margin: 0 21px;
      width: 10px;
    }
  }
  &-1 {
    transform: translateX(-50%) rotate(4.83deg);
    @media (max-width: 920px) {
      transform: translateX(-50%) rotate(10deg);
    }
  }
  &-2 {
    transform: translateX(-50%) rotate(-2.5deg);
    background: var(--color-secondary);
    @media (max-width: 920px) {
      transform: translateX(-50%) rotate(-10deg);
    }
  }
}
</style>
